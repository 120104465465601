import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"

const TrendingMaxi = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)

  return (
    <Layout>
      <Seo
        title={descriptionData.archive_maxi.pageTitle}
        description={descriptionData.archive_maxi.pageDescription}
      />

      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 class="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.archive_maxi.heading}
        </h1>
        <p className="hidden md:block">
          {descriptionData.archive_maxi.description1}
        </p>
        <p className="text-l mt-5 mb-5 font-bold">
          Website data refreshes every Monday, Wednesday, Friday
        </p>
      </div>

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="TMaxi"/>
      </div>
      <p className="lg:hidden">{descriptionData.archive_maxi.description1}</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "archive_dress_maxi" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.archive_maxi.pageTitle}
    description={descriptionData.archive_maxi.pageDescription}
  />
)

export default TrendingMaxi
